import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { post } from "../services/apiService";
import CheckAnimation from "../components/CheckAnimation";

const Checkout = () => {
  const {authUser, cartItems, setCartItems} = useContext(AuthContext);

  const [credentials, setCredentials] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    address: '',
    town: '',
    email: '',
    notes: '',
    agreeToTerms: false
  });
  const [errors, setErrors] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    address: '',
    town: '',
    email: '',
    notes: '',
    agreeToTerms: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(cartItems.length == 0){
      navigate('/search');
      return;
    }
    if (authUser) {
      setCredentials({
        firstname: authUser.firstName || '',
        lastname: authUser.lastName || '',
        phone: authUser.phone || '',
        address: authUser.address || '',
        town: authUser.town || '',
        email: authUser.email || '',
        notes: '',
        payMode: 'wave',
        agreeToTerms: false
      });
    }
  }, []);

  const validate = () => {
    const newErrors = {};
  
    if (!credentials.firstname) newErrors.firstname = 'le prénom est requis';
    if (!credentials.lastname) newErrors.lastname = 'le nom  est requis';
    if (!credentials.email) newErrors.email = 'L\'adresse email est requise';
    if (!credentials.phone) {
      newErrors.phone = 'Le numéro de téléphone est requis';
    } else if (!/^7[05678]\d{7}$/.test(credentials.phone)) {
      newErrors.phone = 'Le numéro n\'est pas valide';
    }
    if (!credentials.address) newErrors.address = 'L\'adresse est requise';
    if (!credentials.town) newErrors.town = 'La ville est requise';
    if (!credentials.agreeToTerms) newErrors.agreeToTerms = 'Veuillez accepter les termes et conditins d\'achat';
  
    setErrors(newErrors);
  
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };
  

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setCredentials(prevcredentials => ({
      ...prevcredentials,
      [name]: type === 'checkbox' ? checked : value
    }));
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (validate()) {
      try{
        let response = await post('/checkout', credentials);
        window.location = response.url;
      }catch(error){
        console.log('Une erreur est survenue');
      }finally{
        setIsLoading(false);
      }
    }else
      setIsLoading(false);
  }
  
  return (
    <div className="mx-auto max-w-7xl">
      <div className="text-center text-5xl font-medium h-64 flex items-center justify-center">
        Paiement
      </div>
      <div className="flex lg:flex-row flex-col">
        <div className="w-full lg:w-3/5 px-6">
          <div className="text-2xl">Informations</div>
          <div className="my-3">
            <form className="p-12 shadow">
              <div className="flex flex-col mb-6">
                <label className="mb-2 font-medium">Nom</label>
                <input name="lastname" value={credentials.lastname} onChange={handleChange} className="px-3 py-3 border-2 border-gray-200 hover:border-gray-600 focus:border-gray-600 transition transition-duration-300 outline-none" />
                {errors.lastname && <span className="text-sm text-red-600">{errors.lastname}</span>}
              </div>
              <div className="flex flex-col mb-6">
                <label className="mb-2 font-medium">Prénom</label>
                <input name="firstname" value={credentials.firstname} onChange={handleChange} className="px-3 py-3 border-2 border-gray-200 hover:border-gray-600 focus:border-gray-600 transition transition-duration-300 outline-none" />
                {errors.firstname && <span className="text-sm text-red-600">{errors.firstname}</span>}
              </div>
              <div className="flex flex-col mb-6">
                <label className="mb-2 font-medium">Numéro de téléphone</label>
                <input name="phone" value={credentials.phone} onChange={handleChange} className="px-3 py-3 border-2 border-gray-200 hover:border-gray-600 focus:border-gray-600 transition transition-duration-300 outline-none" />
                {errors.phone && <span className="text-sm text-red-600">{errors.phone}</span>}
              </div>
              <div className="flex flex-col mb-6">
                <label className="mb-2 font-medium">Email</label>
                <input name="email" value={credentials.email} onChange={handleChange} className="px-3 py-3 border-2 border-gray-200 hover:border-gray-600 focus:border-gray-600 transition transition-duration-300 outline-none" />
                {errors.email && <span className="text-sm text-red-600">{errors.email}</span>}
              </div>
              <div className="flex flex-col mb-6">
                <label className="mb-2 font-medium">Adresse</label>
                <input name="address" value={credentials.address} onChange={handleChange} className="px-3 py-3 border-2 border-gray-200 hover:border-gray-600 focus:border-gray-600 transition transition-duration-300 outline-none" />
                {errors.address && <span className="text-sm text-red-600">{errors.address}</span>}
              </div>
              <div className="flex flex-col mb-6">
                <label className="mb-2 font-medium">Ville</label>
                <input name="town" value={credentials.town} onChange={handleChange} className="px-3 py-3 border-2 border-gray-200 hover:border-gray-600 focus:border-gray-600 transition transition-duration-300 outline-none" />
                {errors.town && <span className="text-sm text-red-600">{errors.town}</span>}
              </div>
              <div className="flex flex-col mb-6">
                <label className="mb-2 font-medium">Notes de livraison</label>
                <textarea name="notes" value={credentials.notes} onChange={handleChange} rows={6} placeholder="Notes pour le livraur, précisions sur la commande, ..." className="px-3 py-3 border-2 border-gray-200 hover:border-gray-600 focus:border-gray-600 transition transition-duration-300 outline-none"></textarea>
                {errors.notes && <span className="text-sm text-red-600">{errors.notes}</span>}
              </div>
            </form>
          </div>
        </div>
        <div className="w-full lg:w-2/5 px-6 lg:mt-0 mt-6">
          <div className="">Commandes</div>
          <div className="bg-gray-50 my-3 rounded py-12 px-6 shadow">
            <div className="font-medium">Produits</div>
            <div>
            {cartItems && cartItems.map((cart, index) =>
              <div className="text-sm py-3 flex flex-row" key={index}>
                <div className="flex-auto">
                  <div>{cart.product.name}</div>
                  <div>
                    <span className="text-gray-400">Boutique: </span>
                    <span>{cart.product.shop.name}</span>
                  </div>
                </div>
                <div className="flex">
                  <div className="mr-6 text-gray-500">x{cart.quantity}</div>
                  <div className="font-semibold">{cart.product.price}</div>
                </div>
              </div>
            )}
            </div>
            <div className="flex justify-between w-full my-6">
              <span>Total: </span>
              <span className="font-bold">{cartItems.reduce((acc, cart) => acc + cart.product.price * cart.quantity, 0)}</span>
            </div>
            <div className="">
              <label>Mode de paiement:</label>
              <div className="flex py-6">
                <input type="radio" name="" checked />
                <img src="/wave-logo.png" className="ml-4 w-16" />
              </div>
            </div>
            <div className="text-sm">
              <label className="text-gray-500">
                <input type="checkbox" name="agreeToTerms" checked={credentials.agreeToTerms} onChange={handleChange} className="mr-3" />
                J'ai lu et j'accepte les <a href="#" className="underline text-gray-900">termes et conditions d'achat</a>
              </label>
              {errors.agreeToTerms && <div className="text-sm text-red-600">{errors.agreeToTerms}</div>}
            </div>
            <Link to="/checkout" className="mt-12 text-white w-full font-semibold block text-center border-0 py-4 px-6 focus:outline-none rounded submit" onClick={handleSubmit}>
              {isLoading ? <div className="flex justify-center items-center"><span className="spinner"></span></div> : 'Payer'}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checkout;
